import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import './components/Header.css';
import './components/MainContent.css'
import './components/AddRecipe.css'
import './components/FindRecipe.css'
import App from './App';
import { RecoilRoot } from 'recoil';
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
    >
        <React.StrictMode>
        <RecoilRoot>
            <App />
        </RecoilRoot>
        </React.StrictMode>
    </Auth0Provider>,
    document.getElementById('root')
  );
