import React, { useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import { viewAddRecipedEnabled, viewFindRecipeEnabled, selectedRecipeId, viewEditRecipe, viewFindByGrid } from '../atoms/MenuAtoms';
import { useRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import ExpandCircleDownTwoToneIcon from '@mui/icons-material/ExpandCircleDownTwoTone';
import ViewAgendaTwoToneIcon from '@mui/icons-material/ViewAgendaTwoTone';
import ViewColumnTwoToneIcon from '@mui/icons-material/ViewColumnTwoTone';
import { useAuth0 } from "@auth0/auth0-react";

// API
const API = 'https://api.airtable.com/v0/appstHdwgUlLLrE1h/';
const Table = 'Recipes';
const Key = process.env.REACT_APP_JUMBA_KEY;

// Expand
const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

// Store previous search for state compare.
const PreviousSearchString = (value) => {
    const ref = React.useRef();

    useEffect(() => {
      ref.current = value;
    });
    
    return ref.current;
};

function FindRecipe() {
    // Atoms
    const [enabledAddRecipe, setEnabledAddRecipe] = useRecoilState(viewAddRecipedEnabled);
    const [enabledFindRecipe, setEnabledFindRecipe] = useRecoilState(viewFindRecipeEnabled);
    const [enableEditRecipe, setEditRecipe] = useRecoilState(viewEditRecipe);
    const [editRecipeId, setSelectedRecipeId] = useRecoilState(selectedRecipeId);
    const [findByGrid, setViewFindByGrid] = useRecoilState(viewFindByGrid);

    // State.
    const [recipeData, setRecipeData] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [formSuccess, setFormSuccess] = React.useState(false);
    const [formError, setFormError] = React.useState(false);
    const [selectedRecipeForExpand, setSelectedRecipeForExpand] = React.useState('');
    const [savedSearchString, setSavedSearchString] = React.useState('');
    const [userRole, setUserRole] = React.useState('guest');

    const previousSearchString = PreviousSearchString(savedSearchString);

    // Auth0
    const { user } = useAuth0();

    // User Role Setup
    useEffect(() => {
        if(user)
        {
            if ( user["https://recipe.johnbrickner.com/roles"].roles !== undefined )
            {
                setUserRole(user["https://recipe.johnbrickner.com/roles"].roles[0]);
            }
        }
    }, [user, userRole, setUserRole]);

    
    // Define datagrid columns.
    const columns = [];

    columns.push(
        {
            field: "VisualCard",
            headerName: "Recipe",
            width: 160,
            renderCell: (params) => (
                <Card sx={{ minWidth: 140, minHeight: 140, backgroundColor: '#d5d5d5' }}>
                    <CardContent sx={{ padding: '8px' }}>
                        <Typography>
                            <strong>
                                {params.row.fields.RecipeName}
                            </strong>
                        </Typography>
                    </CardContent>
                    <CardMedia
                        component="img"
                        height="120"
                        width="120"
                        image={constructImageCover(params.row.fields.RecipeCover)}
                    />
                </Card>
            )
        }
    );

    columns.push(
        {
            field: 'RecipeSummary',
            headerName: 'Summary',
            width: 150,
            flex: 1,
            renderCell: (params) => (
                <Box component={'p'} sx={{verticalAlign: 'top', height: 140}}>
                    {params.row.fields.RecipeSummary}
                </Box>
            )
        }
    );

    if (userRole === 'admin') {
        columns.push(
            {
                field: 'actions',
                headerName: '',
                type: 'actions',
                width: 80,
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<DeleteForeverIcon />}
                        label="Delete"
                        onClick={() => { handleRecipeDeleteConfirmation(params.id) }}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={() => { handleRecipeEdit(params.id) }}
                    />
                ]
            }
        );
    }

    // Datagrid row action functions.
    const handleRecipeDeleteConfirmation = (recipeId) => {
        // Set the recipe ID for the delete if the user choses to proceed.
        setSelectedRecipeId(recipeId);

        // Open the confirmation dialog.
        setOpenConfirmation(true);
    }

    const handleRecipeDelete = () => {
        // Note recipeId is set during the confirmation.
        fetch(API + Table + '/' + editRecipeId + '?api_key=' + Key, {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something unexpected has happened!');
            }
        })
        .then(data => {
            // Close the confirmation dialog.
            setOpenConfirmation(false);

            // Delete action was a success, let the user know.
            setFormSuccess(true);
            setFormError(false);

            // Remove alert on success after a period of time.
            setTimeout(() => {
                setFormSuccess(false);
            }, 3000);

            // Reload grid.
            findRecipe();
        })
        .catch(err => {
            // Something didn't work right, display an error to user.
            setFormSuccess(false);
            setFormError(true);

            // Log error.
            console.log(err);
        });
    }

    const handleRecipeEdit = (recipeId) => {
        // Store the recipe ID for use in edit form.
        setSelectedRecipeId(recipeId);

        // Change views.
        setEnabledAddRecipe(true);
        setEnabledFindRecipe(false);

        // Tell the 'add' form we want to edit this recipe.
        setEditRecipe(true);
    }

    // Datagrid functon for finding the recipes.
    const findRecipe = useCallback(() => {
        let recipeDataStore = [];
        let APIEndString = '';

        const handleRecipeData = (data) => {
            data.records.map(obj => recipeDataStore.push(obj)); 
            return recipeDataStore;
        }

        if (savedSearchString !== '')
        {
            // Build search string to filter the results based on search input.
            APIEndString = '?filterByFormula=OR('
            APIEndString += 'SEARCH(LOWER("' + savedSearchString + '"), LOWER(RecipeName)),';
            APIEndString += 'SEARCH(LOWER("' + savedSearchString + '"), LOWER(RecipeSummary)),';
            APIEndString += 'SEARCH(LOWER("' + savedSearchString + '"), LOWER(RecipeDirections)),';
            APIEndString += 'SEARCH(LOWER("' + savedSearchString + '"), LOWER(RecipeIngredients))';
            APIEndString += ')&api_key=' + Key;
        }
        else
        {
            // Pull everything back.
            // TODO: May need to not allow this or implement paging.
            APIEndString = '?api_key=' + Key;
        }

        fetch(encodeURI(API + Table + APIEndString), {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something unexpected has happened!');
            }
        })
        .then(data => {
            // Load the datagrid with the returned data.
            setRecipeData(handleRecipeData(data));
        })
        .catch(err => {
            // Something didn't work right, display an error to user.
            setFormSuccess(false);
            setFormError(true);

            // Log error.
            console.log(err);
        });
    }, [savedSearchString]);

    // Misc functions.
    const handleRecipeExpandClick = (id) => {
        if (id !== selectedRecipeForExpand)
        {
            // Open expand.
            setSelectedRecipeForExpand(id);    
        }
        else
        {
            // Same expand was clicked again so we need to close it.
            setSelectedRecipeForExpand('');
        }
    };

    const handleConfirmationClose = () => {
        // Clear the selected recipe ID since the user is chosing to not dlete at this time.
        setSelectedRecipeId('');

        // Close the dialog.
        setOpenConfirmation(false);
    };

    const constructIngredients = (ingredients) => {
        return (
            ingredients.map((ingredient, index) => {
                return(
                    <Grid container sx={{width: '100%', lineHeight: 1.3}} key={index}>
                        <Grid item container xs={2} justifyContent='flex-end' sx={{paddingRight: '10px'}}>{ingredient.ingredientAmount}</Grid>
                        <Grid item xs={1.5}>{ingredient.ingredientUnit === 'na' ? '' : ingredient.ingredientUnit}</Grid>
                        <Grid item xs>{ingredient.ingredientName}</Grid>
                    </Grid>
                )
            })
        )
    }

    const constructImageCover = (imageURL) => {
        if (imageURL === undefined) {
            return './assets/no-image.png';
        }
        else
        {
            return imageURL[0].url;
        }
    }

    const handleRecipeViewStyle = (viewType) => {
        setViewFindByGrid(viewType === 'grid' ? true: false);
    }

    const handleSearch = (e) => {
        if(e.keyCode === 13)
        {
            setSavedSearchString(e.target.value);
        }
    }

    useEffect(() => {
        if (savedSearchString !== previousSearchString)
        {
            findRecipe();
        }
    }, [previousSearchString, savedSearchString, findRecipe]);

    const stackItems = recipeData.map((recipeItem) => {
        return(
            <Card key={recipeItem.id}>
                {/* Main outer grid. */}
                <Grid container direction="column">
                    {/* Top row for image on left and name / summary on right. */}
                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            {/* Recipe Cover */}
                            <Grid item sx={{width: 300}}>
                                <CardMedia
                                    component="img"
                                    height={300}
                                    image={constructImageCover(recipeItem.fields.RecipeCover)}
                                />
                            </Grid>
                            {/* Recipe Name / Summary */}
                            <Grid item xs display="flex" flexDirection="column" sx={{backgroundImage: 'url("./assets/recipe-card-backing.svg")', backgroundSize: 'cover'}}>
                                <CardContent sx={{whiteSpace: 'pre-wrap'}}>
                                    <Typography gutterBottom variant="h6">
                                        <strong>
                                            {recipeItem.fields.RecipeName}
                                        </strong>
                                    </Typography>
                                    <Typography>
                                        {recipeItem.fields.RecipeSummary}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{marginTop: 'auto'}}>
                                    <IconButton sx={{marginLeft: 'auto'}} onClick={() => { handleRecipeDeleteConfirmation(recipeItem.id) }}>
                                        { userRole === 'admin' &&
                                            <DeleteForeverIcon fontSize='large' />
                                        }
                                    </IconButton>
                                    <IconButton sx={{marginLeft: 'auto'}} onClick={() => { handleRecipeEdit(recipeItem.id) }}>
                                        { (userRole === 'admin' || userRole === 'user') &&
                                            <EditIcon fontSize='large' />
                                        }
                                    </IconButton>
                                    <ExpandMore
                                        expand={recipeItem.id === selectedRecipeForExpand}
                                        onClick={() => handleRecipeExpandClick(recipeItem.id)}
                                        aria-expanded={recipeItem.id === selectedRecipeForExpand}
                                    >   
                                        <ExpandCircleDownTwoToneIcon fontSize='large' />
                                    </ExpandMore>
                                </CardActions>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{backgroundImage: 'url("./assets/recipe-card-backing-expand.svg")', backgroundSize: 'contain'}}>
                        {/* Recipe Ingredients / Directions */}
                        <Collapse in={recipeItem.id === selectedRecipeForExpand} timeout="auto" unmountOnExit sx={{borderTop: '1px solid #999'}}>
                            <CardContent>
                                <Grid container direction="row" sx={{width: '100%'}}>
                                    {/* Recipe Ingredients */}
                                    <Grid item sx={{width: '35%'}}>
                                        <p><strong>Ingredients</strong></p>
                                        <Grid container sx={{width: '100%'}}>
                                            {constructIngredients(JSON.parse(recipeItem.fields.RecipeIngredients))}
                                        </Grid>
                                    </Grid>
                                    {/* Recipe Directions */}
                                    <Grid item sx={{width: '65%', whiteSpace: 'pre-wrap'}}>
                                        <p><strong>Directions</strong></p>
                                        {recipeItem.fields.RecipeDirections}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Collapse>
                    </Grid>
                </Grid>
            </Card>
        );
    });

    return (
        <Fade in={enabledFindRecipe} timeout={{ enter: 400, exit: 400 }} onEnter={findRecipe}>
            <div className="contentModule" style={{ display: enabledFindRecipe ? "block" : "none" }}>
                <Collapse in={formSuccess}>
                    <Alert variant="filled" severity="success">
                        Recipe was deleted successfully.
                    </Alert>
                    <br/>
                </Collapse>
                <Collapse in={formError}>
                    <Alert variant="filled" severity="error">
                        Something went wrong with the delete operation. Try again.
                    </Alert>
                    <br/>
                </Collapse>
                <Dialog
                    open={openConfirmation}
                    onClose={handleConfirmationClose}
                >
                    <DialogContent>
                        <DialogContentText>
                            Are you certain you want to delete this recipe?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmationClose}>No, Cancel</Button>
                        <Button onClick={handleRecipeDelete} autoFocus>Yes, Delete</Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={2} alignItems='center' sx={{paddingBottom: '15px'}}>
                    <Grid item xs>
                        <Typography fontSize={18}>
                            <strong>Available Recipes</strong>
                        </Typography>
                    </Grid>
                    <Grid item sx={{marginLeft: 'auto'}}>
                        <TextField
                            label="Search"
                            onKeyDown={handleSearch}
                            sx={{paddingRight: '20px'}}
                        />
                        <IconButton sx={{marginLeft: 'auto'}} onClick={() => handleRecipeViewStyle('card')}>
                            <ViewAgendaTwoToneIcon fontSize='large' />
                        </IconButton>
                        <IconButton sx={{marginLeft: 'auto'}} onClick={() => handleRecipeViewStyle('grid')}>
                            <ViewColumnTwoToneIcon fontSize='large' />
                        </IconButton>
                    </Grid>
                </Grid>
                {
                // Conditional render based on menu choice. 
                findByGrid ?
                    <Grid container direction="column">
                        <Grid item xs sx={{display: 'flex'}}>
                            <Card sx={{width: '100%'}}>
                                <CardContent className="findRecipeCardArea">
                                    <Typography gutterBottom variant="h6">
                                        Recipe Collection
                                    </Typography>
                                    <DataGrid
                                        rows={recipeData}
                                        columns={columns}
                                        autoHeight={true}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        rowsPerPageOptions={[5, 10]}
                                        pagination
                                        className="dataGridBase"
                                        getRowId={row => row.id}
                                        getRowHeight={({ id }) => {
                                            /*
                                            if (id % 2 === 0) {
                                            return 100 * densityFactor;
                                            }
                                
                                            return null;
                                            */
                                        return 180;
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                :
                    <Box sx={{ width: '100%' }}>
                        <Stack spacing={2}>
                            {stackItems}
                        </Stack>
                    </Box>
                }
            </div>
        </Fade>      
    );
}

export default FindRecipe;