import React, { useEffect } from 'react';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import { viewAddRecipedEnabled, viewFindRecipeEnabled, viewAccountsEnabled, viewEditRecipe, selectedRecipeId, viewFindByGrid } from '../atoms/MenuAtoms'
import { useRecoilState } from 'recoil';
import IconButton from '@mui/material/IconButton';
import { useAuth0 } from "@auth0/auth0-react";

function Header() {
    // Atoms.
    const [enabledAddRecipe, setEnabledAddRecipe] = useRecoilState(viewAddRecipedEnabled);
    const [enabledFindRecipe, setEnabledFindRecipe] = useRecoilState(viewFindRecipeEnabled);
    const [enabledAccounts, setEnabledAccounts] = useRecoilState(viewAccountsEnabled);
    const [enableEditRecipe, setViewEditRecipe] = useRecoilState(viewEditRecipe);
    const [recipeId, setSelectedRecipeId] = useRecoilState(selectedRecipeId);

    // React State
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userRole, setUserRole] = React.useState('guest');
    const open = Boolean(anchorEl);

    // Auth0
    const { isAuthenticated, logout, user } = useAuth0();

    // Methods
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        // Always set the edit view to false if switching and clear any recipe ID.
        setViewEditRecipe(false);
        setSelectedRecipeId('');

        switch(event.currentTarget.id) {
            case 'addRecipe':
                setEnabledAddRecipe(true);
                setEnabledFindRecipe(false);
                setEnabledAccounts(false);
                break;

            case 'findRecipeTile':
                setEnabledAddRecipe(false);
                setEnabledFindRecipe(true);
                setEnabledAccounts(false);
                break;

            case 'findRecipeGrid':
                setEnabledAddRecipe(false);
                setEnabledFindRecipe(true);
                setEnabledAccounts(false);
                break;

            case 'manageAccount':
                setEnabledAddRecipe(false);
                setEnabledFindRecipe(false);
                setEnabledAccounts(true);
                break;

            case 'logoutUser':
                logout({ returnTo: window.location.origin });
                break;
            
            default:
                console.log("Do nothing, user clicked off menu to close.");
                break;
        }

        setAnchorEl(null);
    };

    // User Role Setup
    useEffect(() => {
        if(user)
        {
            if ( user["https://recipe.johnbrickner.com/roles"].roles !== undefined )
            {
                setUserRole(user["https://recipe.johnbrickner.com/roles"].roles[0]);
            }
        }
    }, [user, userRole, setUserRole]);

    return (
        <div className='headerContainer'>
            <Grid container alignItems='center' sx={{width: '100%'}}>
                <Grid item>
                    <h3 className='titleTreatment'>Brickner Family Recipes</h3>    
                </Grid>
                { isAuthenticated &&
                <Grid item sx={{marginLeft: 'auto'}}>
                    <Typography display={'inline'} className='nicknameTreatment'>
                            <strong>
                                {user.nickname} &nbsp;&nbsp;&nbsp;
                            </strong>
                        </Typography>
                        <IconButton
                            id="fade-button"
                            aria-controls={open ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <MenuRoundedIcon
                                className='titleTreatment'
                            />
                        </IconButton>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button'
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem selected={enabledFindRecipe} onClick={handleClose} id='findRecipeTile'>View Recipes</MenuItem>
                            <Box display={(userRole === 'admin') || (userRole === 'user') ? 'inline' : 'none'}>
                                <MenuItem selected={enabledAddRecipe} onClick={handleClose} id='addRecipe'>Add Recipe</MenuItem>
                            </Box>
                            <Box display={userRole === 'admin' ? 'inline' : 'none'}>
                                <MenuItem selected={enabledAccounts} onClick={handleClose} id='manageAccount'>Manage Accounts</MenuItem>
                            </Box>
                            <MenuItem onClick={handleClose} id='logoutUser'>Logout</MenuItem>
                        </Menu>
                </Grid>
                }
            </Grid>
        </div>
    );
}

export default Header;