import { atom } from "recoil";

export const viewAddRecipedEnabled = atom({
    key: 'viewAddRecipedEnabled',
    default: false
});

export const viewFindRecipeEnabled = atom({
    key: 'viewFindRecipeEnabled',
    default: true
});

export const viewAccountsEnabled = atom({
    key: 'viewAccountsEnabled',
    default: false
});

export const viewEditRecipe = atom({
    key: 'viewEditRecipe',
    default: false
});

export const selectedRecipeId = atom({
    key: 'selectedRecipeId',
    default: ''
});

export const viewFindByGrid = atom({
    key: 'findByGrid',
    default: false
});