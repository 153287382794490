import React from 'react';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import { viewAddRecipedEnabled, viewFindRecipeEnabled, viewEditRecipe, selectedRecipeId } from '../atoms/MenuAtoms'
import { useRecoilValue, useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import PhotoSizeSelectActualTwoToneIcon from '@mui/icons-material/PhotoSizeSelectActualTwoTone';

// API
const API = 'https://api.airtable.com/v0/appstHdwgUlLLrE1h/';
const Table = 'Recipes';
const Key = process.env.REACT_APP_JUMBA_KEY;

function AddRecipe() {
    // Atoms
    const recipeId = useRecoilValue(selectedRecipeId);
    const [enabledAddRecipe, setEnabledAddRecipe] = useRecoilState(viewAddRecipedEnabled);
    const [enabledFindRecipe, setEnabledFindRecipe] = useRecoilState(viewFindRecipeEnabled);
    const [editRecipe, setEditRecipe] = useRecoilState(viewEditRecipe);


    // React State Definitions
    const [recipeName, setRecipeName] = React.useState('');
    const [recipeSummary, setRecipeSummary] = React.useState('');
    const [addIngredientOpen, setOpen] = React.useState(false);
    const [ingredients, setIngredients] = React.useState([]);
    const [ingredientAmount, setIngredientAmount] = React.useState('');
    const [ingredientUnit, setIngredientUnit] = React.useState('tsp');
    const [ingredientName, setIngredientName] = React.useState('');
    const [editIngredient, setEditIngredient] = React.useState(false);
    const [editIngredientId, setEditIngredientId] = React.useState('');
    const [recipeDirections, setRecipeDirections] = React.useState('');
    const [formSuccess, setFormSuccess] = React.useState(false);
    const [formError, setFormError] = React.useState(false);
    const [imageFile, setImageFile] = React.useState('');

    const Input = styled('input')({
        display: 'none',
    });

    // State Handlers
    // Base Form
    const handleRecipeNameChange = (event) => {
        setRecipeName(event.target.value);
    }

    const handleRecipeSummaryChange = (event) => {
        setRecipeSummary(event.target.value);
    }

    // Ingredient Pop
    const handleAddIngredientOpen = () => {
        setOpen(true);
    };

    const handleAddIngredientClose = () => {
        setEditIngredient(false);
        setEditIngredientId('');
        setOpen(false);
    };

    const handleIngredientAmountChange = (event) => {
        setIngredientAmount(event.target.value);
    }

    const handleIngredientUnitChange = (event) => {
        setIngredientUnit(event.target.value);
    }

    const handleIngredientNameChange = (event) => {
        setIngredientName(event.target.value);
    }

    const handleAddIngredient = () => {
        const ingredientsCopy = ingredients.slice();

        // If we are not editing we can just add the new ingreident.
        if (!editIngredient) {
            ingredientsCopy.push({
                ingredientId: uuidv4(),
                ingredientAmount: ingredientAmount,
                ingredientUnit: ingredientUnit,
                ingredientName: ingredientName
            });
        }
        else
        {
            // Locate the current ingredient and alter it's values. 
            for( var i = 0; i < ingredientsCopy.length; i++ ) {
                if ( ingredientsCopy[i].ingredientId === editIngredientId ) {
                    ingredientsCopy[i].ingredientAmount = ingredientAmount;
                    ingredientsCopy[i].ingredientUnit = ingredientUnit;
                    ingredientsCopy[i].ingredientName = ingredientName;
                    break;
                }
            }   
        }

        setIngredients(ingredientsCopy);
        setEditIngredient(false);
        setEditIngredientId('');
        setOpen(false);
    }

    const handleIngredientOrder = (index, direction) => {
        // Make a copy of the current array.
        const ingredientsCopy = ingredients.slice();

        // Swap the entries based on direction
        let newIndex = direction === 'up' ? ( index -1 ) : ( index + 1 );
        let tempEntry = ingredientsCopy[newIndex]; // Store the resident of the targeted slot temporarily.
        ingredientsCopy[newIndex] = ingredientsCopy[index]; // Push in the new resident to the targeted slot.
        ingredientsCopy[index] = tempEntry; // Move the old resident to the now vacant slot.

        // Update the state stored value.
        setIngredients(ingredientsCopy);
    }

    const handleIngredientDelete = (id) => {
        const ingredientsCopy = ingredients.slice();

        // Look for a match in the current ingredients and if found delete it and reload the view.
        for( var i = 0; i < ingredientsCopy.length; i++ ) {
            if ( ingredientsCopy[i].ingredientId === id ) {
                ingredientsCopy.splice(i, 1);
                setIngredients(ingredientsCopy);
                break;
            }
        }
    }

    const handleIngredientEdit = (id) => {
        // Look through the ingredients for a match, when found set dialog elements and display.
        for( var i = 0; i < ingredients.length; i++ ) {
            if ( ingredients[i].ingredientId === id ) {
                setEditIngredientId(id);
                setIngredientAmount(ingredients[i].ingredientAmount);
                setIngredientUnit(ingredients[i].ingredientUnit);
                setIngredientName(ingredients[i].ingredientName);
                setEditIngredient(true);
                setOpen(true);
                break;
            }
        }
    }

    // Directions
    const handleRecipeDirectionsChange = (event) => {
        setRecipeDirections(event.target.value);
    }

    // Base Values
    // TODO: Fetch from backend maybe...
    const measureUnits = [
        {
            value: 'na',
            label: 'Not Applicable'
        },
        {
            value: 'bunch',
            label: 'Bunch'
        },
        {
            value: 'cup',
            label: 'Cup'
        },
        {
            value: 'dash',
            label: 'Dash'
        },
        {
            value: 'g',
            label: 'Gram'
        },
        {
            value: 'ml',
            label: 'Mililiter'
        },
        {
            value: 'oz',
            label: 'Ounce'
        },
        {
            value: 'pinch',
            label: 'Pinch'
        },
        {
            value: 'lb',
            label: 'Pound'
        },
        {
            value: 'sprig',
            label: 'Sprig'
        },
        {
            value: 'tbsp',
            label: 'Tablespoon'
        },
        {
            value: 'tsp',
            label: 'Teaspoon'
        }
    ];

    const ingredientList = ingredients.map((ingredient, index) =>
        <Grid item key={index}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={1}>
                    {ingredient.ingredientAmount}
                </Grid>
                <Grid item xs={1}>
                    {ingredient.ingredientUnit}
                </Grid>
                <Grid item xs>
                    {ingredient.ingredientName}
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => handleIngredientEdit(ingredient.ingredientId)}>
                        <EditIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => handleIngredientDelete(ingredient.ingredientId)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => handleIngredientOrder(index, 'up')} disabled={index === 0}>
                        <ArrowCircleUpRoundedIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => handleIngredientOrder(index, 'down')} disabled={( index + 1 ) === ingredients.length }>
                        <ArrowCircleDownRoundedIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );

    // Function for clearing form on a new add.
    const formSetup = () => {
        if (!editRecipe)
        {
            // As long as we aren't editing the recipe we want to clear the form when the module loads.
            setRecipeName('');
            setRecipeSummary('');
            setRecipeDirections('');
            setIngredients([]);
            setImageFile('');
        }
    }

    // Function for recipe for edit, conditionally.
    const findRecipe = () => {
        if (editRecipe)
        {
            fetch(API + Table + '/' + recipeId + '?api_key=' + Key, {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something unexpected has happened!');
                }
            })
            .then(data => {
                // Load the form.
                setRecipeName(data.fields.RecipeName);
                setRecipeSummary(data.fields.RecipeSummary);
                setRecipeDirections(data.fields.RecipeDirections);
                setIngredients(JSON.parse(data.fields.RecipeIngredients));
                if (data.fields.RecipeCover !== undefined)
                {
                    setImageFile(data.fields.RecipeCover[0].url);
                }
            })
            .catch(err => {
                // Something didn't work right, display an error to user.
                setFormSuccess(false);
                setFormError(true);

                // Log error.
                console.log(err);
            });
        }
    }

    // Saving Recipe
    const handleSavingRecipe = (e) => {
        e.preventDefault();

        // Becasue Airtable can't take an image directly and must grab from an online source,
        // we need to push any cover image up to Cloudinary first, then once we have that URL
        // we can give that to Airtable for the attachment field to work.

        // If the image isn't from Airetable and it's not empty and not undefined, we can process with Cloudinary.
        let processWithCloudinary = false;

        if (typeof imageFile === 'object')
        {
            // If we have an object the user selected an image from the desktop.
            processWithCloudinary = true;
        }

        if (processWithCloudinary)
        {
            // An image was selected, let's process with Cloudinary.
            const formData = new FormData();
            formData.append('file', imageFile);
            formData.append('upload_preset', 'qfaa9u28');

            fetch('https://api.cloudinary.com/v1_1/dfotpdgxn/image/upload', {
                method: 'POST',
                body: formData,
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something unexpected has happened!');
                }
            })
            .then(data => {
                // Save the recipe now with the udpated URL at Cloudinary.
                saveRecipe(data.url);
            })
            .catch(err => {
                // Something didn't work right, display an error to user.
                setFormSuccess(false);
                setFormError(true);

                // Log error.
                console.log(err);
            });
        }
        else
        {
            // Check to see if we have an Airtable image still at this point, if so we'll pass that as the URL so it doesn't upload new.
            if (imageFile.indexOf("airtable") !== -1)
            {
                saveRecipe('skip');
            }
            else
            {
                // We can assume no image and proceed to save without processing an image.
                saveRecipe('');
            }
        } 
    }

    // Save Recipe
    const saveRecipe = (coverImagePath) => {
        // Make the save / update call.
        const callType = editRecipe ? "PATCH" : "POST";
        const callURL = editRecipe ? API + Table + '/' + recipeId + '?api_key=' + Key : API + Table + '?api_key=' + Key;
        let callBody = {};

        if (coverImagePath !== 'skip')
        {
            callBody = JSON.stringify({
                "fields": {
                "RecipeName": recipeName,
                "RecipeSummary": recipeSummary,
                "RecipeDirections": recipeDirections,
                "RecipeIngredients": JSON.stringify(ingredients),
                "RecipeCover": [{ "url": coverImagePath }]
                }
            })
        }
        else
        {
            callBody = JSON.stringify({
                "fields": {
                "RecipeName": recipeName,
                "RecipeSummary": recipeSummary,
                "RecipeDirections": recipeDirections,
                "RecipeIngredients": JSON.stringify(ingredients)
                }
            })
        }

        fetch(callURL, {
            method: callType,
            headers: { 'Content-Type': 'application/json' },
            body: callBody
        })
        .then(response => {
            if (response.status === 200)
            {
                setFormSuccess(true);
                setFormError(false);

                // Remove alert on success after a period of time.
                setTimeout(() => {
                    setFormSuccess(false);
                    
                    // Return the user to the view.
                    setEnabledAddRecipe(false);
                    setEnabledFindRecipe(true);
                }, 1000);

                // Clear form.
                setRecipeName('');
                setRecipeSummary('');
                setRecipeDirections('');
                setIngredients([]);
                setImageFile('');
            }
            else
            {
                // Something didn't work right, display an error to user.
                setFormSuccess(false);
                setFormError(true);

                // Log the response.
                console.log(response);
            }
        })
        .catch(err => {
            // Something didn't work right, display an error to user.
            setFormSuccess(false);
            setFormError(true);

            // Log the error.
            console.log(err);
        });
    }

    // Misc functions
    const handleImageChange = (e) => {
        if (e.target.files.length > 0)
        {
            setImageFile(e.target.files[0]);
        }
    }

    const handleEditCancel = () => {
        setEnabledAddRecipe(false);
        setEnabledFindRecipe(true);
        setEditRecipe(false);
    }

    return (
        <Fade in={enabledAddRecipe} timeout={{ enter: 400, exit: 400 }} onEnter={() => {findRecipe(); formSetup();}}>
            <div className="contentModule" style={{ display: enabledAddRecipe ? "block" : "none" }}>
                <Collapse in={formSuccess}>
                    <Alert variant="filled" severity="success">
                        Recipe added successfully.
                    </Alert>
                    <br/>
                </Collapse>
                <Collapse in={formError}>
                    <Alert variant="filled" severity="error">
                        Something went wrong. Check the form and try again.
                    </Alert>
                    <br/>
                </Collapse>
                <form onSubmit={handleSavingRecipe}>
                    <Grid container direction={"column"} rowSpacing={2}>
                        <Grid item>
                            <Grid container columnSpacing={2} direction="row">
                                <Grid item xs sx={{display: 'flex'}}>
                                    <Card sx={{width: '100%'}}>
                                        <CardContent className="addRecipeCardArea">
                                            <Typography gutterBottom variant="h6">
                                                General Information
                                            </Typography>
                                            <Grid container rowSpacing={2} direction="column">
                                                <Grid item>
                                                    <Grid container columnSpacing={2} direction="row" alignItems="center">
                                                        <Grid item xs>
                                                            <TextField
                                                                required
                                                                id="recipeName"
                                                                label="Recipe Name"
                                                                className="addRecipeInputCommon"
                                                                fullWidth
                                                                value={recipeName}
                                                                onChange={handleRecipeNameChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.5}>
                                                            <label htmlFor="icon-button-file">
                                                                <Input accept="image/*" id="icon-button-file" type="file" onChange={handleImageChange} />
                                                                <IconButton aria-label="upload picture" component="span">
                                                                    <PhotoSizeSelectActualTwoToneIcon fontSize='large' />
                                                                </IconButton>
                                                            </label>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        required
                                                        id="recipeSummary"
                                                        label="Recipe Summary"
                                                        multiline
                                                        rows={3}
                                                        className="addRecipeInputCommon"
                                                        fullWidth
                                                        value={recipeSummary}
                                                        onChange={handleRecipeSummaryChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs sx={{display: 'flex'}}>
                                    <Card sx={{width: '100%'}}>
                                        <CardContent className="addRecipeCardArea">
                                            <Grid container direction="row" alignItems="center">
                                                <Grid item style={{ flexGrow: "1" }}>
                                                    <Typography gutterBottom variant="h6">
                                                        Ingredients
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton sx={{ marginRight: 'auto' }} onClick={handleAddIngredientOpen}>
                                                        <AddCircleIcon fontSize='large' />
                                                    </IconButton>
                                                    <Dialog open={addIngredientOpen} onClose={handleAddIngredientClose}>
                                                        <DialogTitle sx={{paddingBottom: 0}}>
                                                            <Typography fontSize={18}>
                                                                <strong>Ingredient</strong>
                                                            </Typography>
                                                        </DialogTitle>
                                                        <DialogContent sx={{paddingTop: 0, paddingBottom: 0}}>
                                                            <DialogContentText>
                                                                Fill in the amount, unit and ingredient name below.
                                                                <br />
                                                                <br />
                                                            </DialogContentText>
                                                            <Grid container spacing={2} direction="row">
                                                                <Grid item xs={2}>
                                                                    <TextField
                                                                        required
                                                                        id="ingredientAmount"
                                                                        label="Amount"
                                                                        className="addIngredientInputCommon"
                                                                        fullWidth
                                                                        value={ingredientAmount}
                                                                        onChange={handleIngredientAmountChange}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs="auto">
                                                                    <TextField
                                                                        id="ingredientUnit"
                                                                        select
                                                                        label="Select"
                                                                        value={ingredientUnit}
                                                                        onChange={handleIngredientUnitChange}
                                                                    >
                                                                        {
                                                                            measureUnits.map((option) => (
                                                                                <MenuItem key={option.value} value={option.value}>
                                                                                    {option.label}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <TextField
                                                                        required
                                                                        id="ingredientName"
                                                                        label="Name"
                                                                        className="addIngredientInputCommon"
                                                                        fullWidth
                                                                        value={ingredientName}
                                                                        onChange={handleIngredientNameChange}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleAddIngredientClose}>Cancel</Button>
                                                            <Button onClick={handleAddIngredient}>{editIngredient ? "Update List" : "Add To List"}</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} direction="column">
                                                {ingredientList}
                                            </Grid>
                                        </CardContent>    
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Card sx={{width: '100%'}}>
                                <CardContent className="addRecipeCardArea">
                                    <Typography gutterBottom variant="h6">
                                        Directions
                                    </Typography>
                                    <TextField 
                                        required
                                        id="recipeDirections"
                                        label="Recipe Directions"
                                        multiline
                                        rows={5}
                                        className="addRecipeInputCommon"
                                        fullWidth
                                        value={recipeDirections}
                                        onChange={handleRecipeDirectionsChange}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sx={{marginLeft: "auto"}}>
                            <br />
                            {editRecipe ? <Button onClick={() => handleEditCancel()}>Cancel</Button>:""}
                            <Button type="submit">{editRecipe ? "Update Recipe":"Save New Recipe"}</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Fade>      
    );
}

export default AddRecipe;