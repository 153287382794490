import Fade from '@mui/material/Fade';
import { viewAccountsEnabled } from '../atoms/MenuAtoms'
import { useRecoilValue } from 'recoil';

function ManageAccount() {
    const enabled = useRecoilValue(viewAccountsEnabled);

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div className="contentModule" style={{ display: enabled ? "block" : "none" }}>
                <p>Under Development</p>
            </div>
        </Fade>      
    );
}

export default ManageAccount;