import Header from "./components/Header"
import MainContent from "./components/MainContent"
import Paper from '@mui/material/Paper';

function App() {
    return (
        <div>
            <Paper elevation={5}>
                <div>
                    <Header />
                </div>
                <div>
                    <MainContent />
                </div>
            </Paper>
        </div>
    );
}

export default App;