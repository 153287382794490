import * as React from 'react';
import AddRecipe from "./AddRecipe"
import FindRecipe from "./FindRecipe";
import ManageAccount from "./ManageAccount";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";

function MainContent() {
    // Auth0
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    if (!isAuthenticated)
    {
        return (
            <div className='loginContainer'>
                <p><strong>User Login</strong></p>
                <p>The application requires authentication. Please click the login below below to login.</p>
                <Button variant='contained' onClick={() => loginWithRedirect()}>
                    Login
                </Button>
            </div>
        );
    }

    return (
        <div className='mainContainer'>
                <AddRecipe />
                <FindRecipe />
                <ManageAccount />
        </div>
    );
}

export default MainContent;